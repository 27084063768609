const LINE_DURATION = 2;
const LINE_WIDTH_START = 4;

let canvas;
let ctx;
let lineDuration = LINE_DURATION;
let lineWidthStart = LINE_WIDTH_START;
let drawEveryFrame = 1;
let frame = 0;
let points = [];

window.requestAnimFrame = (function() {
    return window.requestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        window.oRequestAnimationFrame ||
        window.msRequestAnimationFrame ||
        function(callback) {
            window.setTimeout(callback, 1000 / 60);
        };
})();

const header = {
    init() {
        this.initEvents()
    },

    initEvents() {
        jQuery(document).on('click', '.header__burger', this.handleMenuClick.bind(this))
        jQuery(document).on('click', '.header__div-button', this.handleCloseClick.bind(this))

        if(window.innerWidth > 1024) {
            jQuery(document).on('ready', this.handleDocumentReady.bind(this));
            jQuery(document).on('pointermove', this.handleMouseMove.bind(this));
        }
    },


    handleMenuClick(e) {
        let page = e.currentTarget.parentNode
        let overlay = Array.from(page.childNodes).find(node => {
            return node.classList && node.classList.contains('header__overlay');
        })

        let menu = Array.from(overlay.childNodes).find(node => {
            return node.classList && node.classList.contains('header__menu');
        })

        jQuery(menu).prop('inert', false)
        jQuery(overlay).prop('inert', false)
    },

    handleCloseClick(e) {
        let menu = e.currentTarget.parentNode
        let header = menu.parentNode

        jQuery(menu).prop('inert', true)
        jQuery(header).prop('inert', true)
    },

    handleDocumentReady() {
        if (canvas === undefined) {
            canvas = document.getElementById('canvas');
            ctx = canvas.getContext('2d');
            this.draw()
        }

        if(ctx !== undefined){
            ctx.canvas.width = window.innerWidth - 15
            ctx.canvas.height = window.innerHeight
        }
    },

    handleMouseMove(e) {
        let cursor = document.querySelector(".cursor-ball")
        cursor.style.left = e.pageX - cursor.offsetWidth / 2 + 'px'
        cursor.style.top = e.pageY - cursor.offsetHeight / 2 - window.scrollY + 'px'

        if (frame === drawEveryFrame) {
            points.push({x: e.pageX - window.scrollX, y: e.pageY - window.scrollY, lifetime: 0})
            frame = 0
        }
        frame = frame + 1
    },

    draw(e) {
        if(canvas.height !== window.innerHeight || canvas.width !== window.innerWidth){
            canvas.height = window.innerHeight - ( window.innerHeight - document.documentElement.clientHeight)
            canvas.width = window.innerWidth - ( window.innerWidth - document.documentElement.clientWidth)
        }

        this.animatePoints();
        window.requestAnimationFrame(this.draw.bind(this));
    },

    animatePoints() {
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

        const duration = lineDuration * 300 / 60; // durée necessaire a la ligne pour etre completement efface
        let point, lastPoint;

        ctx.beginPath(); // cree un nouveau chemin (vide les sous chemins)

        for(let i = 0; i < points.length; i++ ){
            point = points[i];

            if(points[i - 1] !== undefined){
                lastPoint = points[i - 1]
            }else{
                lastPoint = points[i];
            }

            point.lifetime += 1; // duree de vie de chaque point
            if(point.lifetime > duration){
                points.splice(i,1) // enleve un element au tableau
            }

            const inc = (point.lifetime / duration); // valeur en tre 0 et 1 qui donne le temps qu'a vecu un point
            const spreadRate = lineWidthStart * (1 - inc); // taille de la ligne de base fois le pourcentage de vie restante (plus il reste de vie plus la ligne est large)

            ctx.lineJoin = "round"; // rend les bordures de debut et de fin ronde
            ctx.lineWidth = spreadRate; // definie la largeur de la ligne
            ctx.strokeStyle = "#75FB4C"

            ctx.moveTo(lastPoint.x, lastPoint.y) // deplace le nouveau points de depart du sous chemain vers celui ci
            ctx.lineTo(point.x, point.y) // connecte le dernier point du sous chemin au coordonnee

            ctx.stroke() // dessine le chemin actuel avec le style de trait actuel
            ctx.closePath() // retour du stylo au point de depart de sous trace et ajoute une ligne entre le point courant et le point rejoint (point)
        }
    },
}

module.exports = header
