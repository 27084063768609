import header from "./components/_header";
import gsap from "gsap"
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
gsap.registerPlugin(DrawSVGPlugin);

import Swiper from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
window.Swiper = Swiper;

const global = {
    init() {
        this.initEvents()
    },

    initEvents() {

        if (window.innerWidth > 1024) {
            jQuery(document).on('mouseenter', '.hover-cursor', this.handleMouseEnter.bind(this));
            jQuery(document).on('mouseleave', '.hover-cursor', this.handleMouseLeave.bind(this));
            jQuery(document).on('mouseenter', '.header__item-category, .footer__link--secondary', this.handleMouseEnterSVG.bind(this));
            jQuery(document).on('mouseleave', '.header__item-category, .footer__link--secondary', this.handleMouseLeaveSVG.bind(this));
        }
    },

    handleMouseEnterSVG(e){
        gsap.fromTo(
            e.currentTarget.querySelector("path"),
            { drawSVG: "0%" },
            { duration: 0.5, drawSVG: "100%" }
        );
    },

    handleMouseLeaveSVG(e){
        gsap.to(
            e.currentTarget.querySelector("path"),
            {duration: 0.5, drawSVG: "0"}
        );
    },

    handleMouseEnter(e) {
        const clickableTags = ['A', 'BUTTON', 'INPUT', 'TEXTAREA', 'SELECT', 'LABEL'];
        const hoverElement = e.currentTarget;
        let cursor = document.querySelector(".cursor-ball");

        if (hoverElement.classList.contains('hover-cursor') || clickableTags.includes(hoverElement.tagName)) {
            cursor.classList.add("cursor-ball--big")
        }
    },

    handleMouseLeave(e) {
        const clickableTags = ['A', 'BUTTON', 'INPUT', 'TEXTAREA', 'SELECT', 'LABEL'];
        const hoverElement = e.currentTarget;
        let cursor = document.querySelector(".cursor-ball");

        if (hoverElement.classList.contains('hover-cursor') || clickableTags.includes(hoverElement.tagName)) {
            cursor.classList.remove("cursor-ball--big")
        }
    },
}

global.init()
header.init()
